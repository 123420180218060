const PrivacyPolicy = () => {
  return (
    <div>
      <h1>“Swell” by Melismatic - Privacy Policy</h1>
      <p>Effective Date: February 1, 2025</p>
      <p>
        Thank you for using Swell by Melismatic ("we," "our," "us"). Your
        privacy is important to us, and this Privacy Policy explains how we
        collect, use, disclose, and protect your information when you use our
        mobile application ("App", “Swell”).
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        When you use Swell, we may collect the following types of information:
      </p>
      <h3>1.1 Information You Provide</h3>
      <p>
        Customer Support: If you contact us for support, we may collect
        information related to your request.
      </p>
      <h3>1.2 Automatically Collected Information</h3>
      <p>
        Usage Data: We collect information about how you interact with the App,
        such as session duration and feature usage.
      </p>
      <p>
        Device Information: We may collect device model, operating system, and
        app version to improve performance.
      </p>
      <p>
        Analytics Data: We use third-party analytics tools (such as Mixpanel) to
        help us understand App usage and improve functionality.
      </p>
      <h3>1.3 Information We Do Not Collect</h3>
      <p>
        We do not collect biometric data, health-related data, or any personally
        identifiable motion data.
      </p>
      <h2>2. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>Improve and personalize your experience within the App.</li>
        <li>Analyze usage trends and optimize performance.</li>
        <li>Provide customer support and respond to inquiries.</li>
        <li>Ensure security and prevent fraud or misuse.</li>
      </ul>
      <h2>3. How We Share Your Information</h2>
      <p>
        We do not sell or rent your personal information. We may share
        information in the following circumstances:
      </p>
      <ul>
        <li>
          Service Providers: We work with third-party providers (e.g., Mixpanel)
          who assist in analytics and performance monitoring.
        </li>
        <li>
          Legal Requirements: If required by law, we may disclose information to
          comply with legal obligations.
        </li>
        <li>
          Business Transfers: In the event of a merger or acquisition, your data
          may be transferred to the new entity.
        </li>
      </ul>
      <h2>4. Data Retention & Deletion Section:</h2>
      <p>
        We retain collected data only as long as necessary for the purposes
        outlined in this policy. You may request deletion of your data by
        contacting us at support@melismatic.ai. Upon request, we will delete
        your personal data from our records and ensure it is also removed from
        third-party service providers in compliance with applicable privacy
        laws.
      </p>
      <h2>5. Security</h2>
      <p>
        We implement security measures to protect your data, including
        encryption and access controls. However, no method of transmission is
        100% secure.
      </p>
      <h2>6. Children’s Privacy</h2>
      <p>
        Swell by Melismatic is not intended for children under 13 years old, and
        we do not knowingly collect any personal data from children. If we learn
        that we have inadvertently collected data from a child under 13, we will
        take immediate steps to delete that information from our records. If you
        are a parent or guardian and believe that your child has provided
        personal information to us, please contact us at support@melismatic.ai
        to request data deletion.
      </p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. We will notify users of
        significant changes via in-app notifications or updates to this page.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at support@melismatic.ai.
      </p>
      <p>
        By using Swell by Melismatic, you agree to the terms of this Privacy
        Policy.
      </p>
    </div>
  );
};
export default PrivacyPolicy;
