import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import HomeContent from "./HomeContent";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomeContent />} />
        <Route path="/swell/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
// TODO Define head title
